import * as React from "react"

import CatError from "../components/Images";

import styled from "styled-components";
import media from "styled-media-query";
import SEO from "../components/seo";

const PostItemError = styled.div`
  width: 600px;
  height: 150px;
  font-size: 10rem;
  margin: 0 auto;
  ${media.lessThan('medium')`
      max-width: 100%;
      font-size: 5rem;
    `}
`;

const PostItemImg = styled.div`
  width: 500px;
  height: 500px;
  ${media.lessThan('medium')`
    max-width: 100%;
  `}
`;

const PostItemWrapper = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;

// markup
const NotFoundPage = () => {
    return (
        <>
            <SEO title="404"/>
            <PostItemWrapper>
                <PostItemError>
                    Oops! 404
                </PostItemError>
                <PostItemImg>
                    <CatError/>
                </PostItemImg>
            </PostItemWrapper>
        </>
    )
}

export default NotFoundPage
